<template>
  <div class="home-class">
    <!-- <el-button size="mini" @click="gosearch()" style="width:100%" type="primary">查询</el-button> -->
    <div style="width: 100%" v-if="active == 0 || active == -1">
      <el-button @click="gosearch()" style="width: 100%; font-size: 20px"
        >查询</el-button
      >
      <el-divider>预约请按以下流程进行</el-divider>
    </div>
    <el-steps :active="active" finish-status="success" class="steps-class">
      <el-step title="车辆预约入校承诺书"></el-step>
      <el-step title="学生信息"></el-step>
      <el-step title="等待审核"></el-step>
    </el-steps>
    <div v-show="active == -1" class="out-div div-public div-144h">
      已退出预约流程

      <el-button
        size="mini"
        type="primary"
        style="margin: 30px 0px"
        @click="
          () => {
            active = 0;
            form = {};
          }
        "
        >重新进入预约流程</el-button
      >
    </div>
    <!-- <div v-show="active == -2" class="out-div div-public div-144h">
      开放时间：<br />7月17日16:00-20:00<br />7月18日8:00-17:00。
    </div> -->
    <div v-show="active == 0" class="one-class div-public div-144h">
      <div class="text-class" v-html="cns"></div>
      <div class="button-class">
        <el-button
          style="width: 80px"
          size="mini"
          type="primary"
          @click="active++"
          >同意</el-button
        >
        <!-- <el-button
          style="width: 80px"
          size="mini"
          type="danger"
          @click="active = -1"
          >不同意</el-button
        > -->
        <el-button style="width: 80px" size="mini" @click="active = -1"
          >不同意</el-button
        >
        <!-- <el-button size="mini" @click="gosearch()">查询</el-button> -->
      </div>
    </div>

    <div v-show="active == 1" class="two-class div-public">
      <el-form ref="form" :model="form" label-width="100px" size="mini">
        <el-form-item label="学生姓名">
          <el-input v-model="form.sname"></el-input>
        </el-form-item>

        <el-form-item label="学部(院)">
          <el-select
            v-model="form.ministry"
            placeholder="请选择学部(院)"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in optionjson"
              :key="'option' + index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="宿舍号">
          <el-input v-model="form.dorm"></el-input>
        </el-form-item>

        <el-form-item label="联系方式">
          <el-input v-model="form.sphonenum"></el-input>
        </el-form-item>

        <el-form-item label="来访人(一)" style="font-weight: 900">
        </el-form-item>

        <el-form-item label="姓名">
          <el-input v-model="form.fname1"></el-input>
        </el-form-item>

        <el-form-item label="身份证号">
          <el-input v-model="form.fidcard1"></el-input>
        </el-form-item>

        <el-form-item label="联系方式">
          <el-input v-model="form.fphone1"></el-input>
        </el-form-item>

        <el-form-item label="车牌号">
          <!-- <el-input
            v-model="form.fcarnum1"
            onkeyup="value=value.replace(/[^A-Z0-9]/g,0)"
          ></el-input> -->
          <el-input
            v-model="form.fcn1b"
            @change="
              () => {
                form.fcarnum1 =
                  form.fcn1a != undefined
                    ? form.fcn1a + form.fcn1b
                    : form.fcn1b;
              }
            "
          >
            <el-select
              v-model="form.fcn1a"
              slot="prepend"
              placeholder="车牌"
              style="width: 80px"
            >
              <el-option
                v-for="(item, index) in cityjson"
                :key="'city1' + index"
                :label="item.code"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item label="来访人(二)" style="font-weight: 900">
          <!-- <el-button
            type="primary"
            icon="el-icon-plus icon-class"
            circle
            @click="l2 = true"
            v-if="!l2"
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-minus icon-class"
            circle
            @click="l2 = false"
            v-if="l2"
          ></el-button> -->
          <i
            class="el-icon-circle-plus-outline icon-class"
            @click="l2 = true"
            v-show="!l2"
          ></i>
          <i
            class="el-icon-remove-outline icon-class"
            @click="l2 = false"
            v-show="l2"
          ></i>
        </el-form-item>

        <el-form-item label="姓名" v-show="l2">
          <el-input v-model="form.fname2"></el-input>
        </el-form-item>

        <el-form-item label="身份证号" v-show="l2">
          <el-input v-model="form.fidcard2"></el-input>
        </el-form-item>

        <el-form-item label="联系方式" v-show="l2">
          <el-input v-model="form.fphone2"></el-input>
        </el-form-item>

        <el-form-item label="车牌号" v-show="l2">
          <el-input
            v-model="form.fcn2b"
            @change="
              () => {
                form.fcarnum1 =
                  form.fcn1a != undefined
                    ? form.fcn1a + form.fcn1b
                    : form.fcn1b;
              }
            "
          >
            <el-select
              v-model="form.fcn2a"
              slot="prepend"
              placeholder="车牌"
              style="width: 80px"
            >
              <el-option
                v-for="(item, index) in cityjson"
                :key="'city2' + index"
                :label="item.code"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item label="健康登记" style="font-weight: 900"></el-form-item>

        <el-form-item label="">
          <div class="inform-div">近14日内本人及家属同确诊病例密接史</div>
          <el-radio-group v-model="form.jcs" class="radio-class">
            <el-radio label="有"></el-radio>
            <el-radio label="无"></el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="">
          <div class="inform-div">
            近14日内本人及家属同确诊病例密接人员的密接史
          </div>
          <el-radio-group v-model="form.mjs" class="radio-class">
            <el-radio label="有"></el-radio>
            <el-radio label="无"></el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="">
          <div class="inform-div">
            当前发热、腹泻、咽痛、干咳或咳痰、嗅觉减退、味觉减退症状
          </div>
          <el-radio-group v-model="form.zz" class="radio-class">
            <el-radio label="有"></el-radio>
            <el-radio label="无"></el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="">
          <div class="inform-div">申请入校日期在居家观察期或健康监测期内</div>
          <el-radio-group v-model="form.jcq" class="radio-class">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="">
          <div class="inform-div">本人“北京健康宝”绿码</div>
          <el-radio-group v-model="form.lm" class="radio-class">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="">
          <div class="inform-div">本人接种了新冠肺炎疫苗</div>
          <el-radio-group v-model="form.ym" class="radio-class">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="预约日期">
          <el-select
            v-model="form.day"
            placeholder="请选择日期"
            style="width: 100%"
            @change="changeday()"
          >
            <el-option
              v-for="(item, index) in dayoption"
              :key="'day' + index"
              :label="index"
              :value="index"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="预约时间段">
          <el-select
            v-model="form.time"
            placeholder="请选择时间段"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in timeoption"
              :key="'time' + index"
              :label="item.time + '(剩余' + item.num + '个)'"
              :value="item.time"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="updata()">预约</el-button>
          <el-button @click="active = -1">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="active == 2" class="three-class div-public">
      <div class="yym-div">您的预约码为：{{ getyym() }}</div>
      <div class="yym-div"><b>请务必保存预约码，便于查询审核状态。</b></div>
      <vue-qr
        :text="qrdata.url"
        :margin="0"
        colorDark="#409EFF"
        colorLight="#fff"
        :logoScale="0.3"
        :size="300"
      ></vue-qr>
      <div style="color: #f00; margin: 10px 0px">
        <b>请长按二维码保存，识别二维码即可查询审核状态</b>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import md5 from "js-md5";
export default {
  components: {
    vueQr,
  },
  data() {
    return {
      l2: false,
      cityjson: {},
      active: 0,
      cns: "",
      optionjson: [],
      form: {},
      dayoption: [],
      timeoption: [],
      retnum: "",
      qrdata: {
        url: window.location.href.split("/#/")[0] + "/#/search?s=",
      },
    };
  },
  mounted() {
    let t = new Date();
    let year = t.getFullYear();
    let month = t.getMonth() + 1;
    let date = t.getDate();
    let hours = t.getHours();
    let minutes = t.getMinutes();

    // if (
    //   !(
    //     (date == 17 && hours >= 16 && hours <= 20) ||
    //     (date == 18 && hours >= 8 && hours <= 17)
    //   )
    // ) {
    //   this.active = -2;
    // }
    this.$axios.get("text.json").then((res) => {
      this.cns = res.data.cns;
      this.optionjson = res.data.option;
    });

    this.$axios.get("carjson.json").then((res) => {
      // let j = {};
      // for (let i of res.data.data) {
      //   if (j[i.province]) {
      //     j[i.province].push(i);
      //   } else {
      //     j[i.province] = [i];
      //   }
      // }
      // this.cityjson = j;
      this.cityjson = res.data.data;
    });

    this.$axios.get("/carapi/getcar.php").then((res) => {
      this.dayoption = res.data;
    });
  },
  methods: {
    gosearch() {
      this.$router.push("/search");
    },
    updata() {
      // this.form.fcarnum1 &&
      if (
        this.form.sname &&
        this.form.ministry &&
        this.form.dorm &&
        this.form.sphonenum &&
        this.form.fname1 &&
        this.form.fidcard1 &&
        this.form.fphone1 &&
        this.form.day &&
        this.form.time
      ) {
        let formdata = new FormData();
        for (let i in this.form) {
          // formdata.append(i, this.form[i].toUpperCase());
          formdata.append(i, this.form[i]);
        }

        let yym1 = "";
        for (let i of this.form.day.split(".")) {
          if (i.length < 2) {
            yym1 = yym1 + "0" + i;
          } else {
            yym1 = yym1 + i;
          }
        }

        let yym2 =
          this.form.time.split("-")[0].split(":")[0].length < 2
            ? "0" + this.form.time.split("-")[0].split(":")[0]
            : this.form.time.split("-")[0].split(":")[0];
        let time = new Date();
        let yym = time.getFullYear() + yym1 + yym2;
        formdata.append("yym", yym);

        this.$axios.post("/carapi/reform.php", formdata).then((res) => {
          if (res.data.message == "success") {
            this.retnum = res.data.num;
            if (this.retnum < 10) {
              this.retnum = "0" + this.retnum;
            }
            this.qrdata.url =
              window.location.href.split("/#/")[0] +
              "/#/search?s=" +
              md5(this.form.sphonenum + "" + res.data.num);
            this.active++;
          } else {
            this.$message.error("预约人数已满，请重新选择时间！");
            this.$axios.get("/carapi/getcar.php").then((res) => {
              this.dayoption = res.data;
              this.timeoption = [];
              this.form.day = "";
              this.form.time = "";
            });
          }
        });
      } else {
        this.$message.error("信息不完整，请填写完整信息！");
      }
    },
    changeday() {
      this.timeoption = this.dayoption[this.form.day];
    },
    getyym() {
      let yym1 = "";
      for (let i of this.form.day.split(".")) {
        if (i.length < 2) {
          yym1 = yym1 + "0" + i;
        } else {
          yym1 = yym1 + i;
        }
      }

      let yym2 =
        this.form.time.split("-")[0].split(":")[0].length < 2
          ? "0" + this.form.time.split("-")[0].split(":")[0]
          : this.form.time.split("-")[0].split(":")[0];
      let yym3 = this.retnum;
      // let yym3 = this.retnum.length < 2 ? "0" + this.retnum : this.retnum;
      let time = new Date();
      return time.getFullYear() + yym1 + yym2 + yym3;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-class {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .steps-class {
    width: 96%;
    padding-top: 10px;
  }
  .div-public {
    width: 96%;
    height: calc(100vh - 67px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .div-144h {
    height: calc(100vh - 162px) !important;
  }
  .out-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #f00;
    font-size: 30px;
    font-weight: 900;
  }
  .one-class {
    line-height: 2em;
    text-indent: 2em;
    .text-class {
      padding-bottom: 30px;
    }
    .button-class {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 20px;
      // position: fixed;
      // bottom: 100px;
      // width: 100%;
    }
  }
  .two-class {
    .radio-class {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .inform-div {
      font-weight: 900;
    }
    .icon-class {
      font-size: 20px;
      cursor: pointer;
      // font-weight: 900;
    }
  }
  .three-class {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    .yym-div {
      font-size: 20px;
      margin: 10px 0px;
    }
  }
}
</style>